import { useEffect, useState, useRef } from 'react';
import './Home.scss';
import siete from '../assets/siete.svg';
import Products from '../components/Products';
import Reservation from '../components/Reservation';
const Home = ({ categories }) => {
	const selectRef = useRef(null);
	const [selected, setSelected] = useState(null);
	const handleCategoryChange = (event) => {
		setSelected(categories.find((c) => parseInt(c.id) === parseInt(event.target.value)));
	};

	if (!categories) {
		return null;
	}

	useEffect(() => {
		if (categories) {
			const initialCategory = categories.find((c) => c.name.startsWith('Ramyun'));
			if (initialCategory) {
				selectRef.current.value = initialCategory.id;
				setSelected(initialCategory);
			}
		}
	}, [categories]);

	return (
		<main className='home'>
			<img src={siete} style={{ width: '200px', height: 'auto' }} titple='Siete Olas' />
			<h1>Bienvenidos a Siete Olas!</h1>
			<div className='info'>
				<p>Somos la primera y mayor tienda de productos importados de Corea en Santa Marta.</p>
				<p>Tenemos gran variedad de productos como Ramen, Cup Ramen, Dulces, Bebidas con y sin alcohol.</p>
				<p>Explora nuestro catálogo de productos aquí debajo y realiza tu pedido por Instragram o WhatsApp.</p>
			</div>
			<hr />
			<select className='categories' onChange={handleCategoryChange} ref={selectRef}>
				{categories.map((category, i) => (
					<option value={category.id} key={i}>
						{category.name}
					</option>
				))}
			</select>
			{selected && <Products products={selected.products} key={selected.id} />}
		</main>
	);
};

export default Home;
