import { NavLink, useHistory } from 'react-router-dom';

import './Header.scss';
import Reservation from './Reservation';

const Header = () => {
	const history = useHistory();

	const handleGoToHome = () => {
		history.push('/');
	};
	return (
		<header>
			<span className='logo' onClick={handleGoToHome}>
				sie<span className='siete'>7</span>eolas
			</span>
			<ul className='page-links'>
				<li>
					<NavLink to='/' activeClassName='active'>
						Tienda Online
					</NavLink>
				</li>
				<li>
					<Reservation />
				</li>
			</ul>
		</header>
	);
};

export default Header;
