import './Product.scss';
import { useState } from 'react';

const Product = ({ product, key }) => {
	const getProductImage = (image) => {
		return 'https://admin.posfacil.co/images/' + image;
	};
	const [open, setOpen] = useState(false);
	const toggleModal = () => {
		setOpen(!open);
	};

	return (
		<>
			<div key={key} className='product' onClick={toggleModal}>
				{product.image && (
					<div className='image'>
						<img src={getProductImage(product.image)} alt={product.name} />
					</div>
				)}
				{product.name}
				<div className='price'>${product.price}</div>
			</div>
			<div className={`modal ${open ? 'open' : ''}`} onClick={toggleModal}>
				<div className='modal-content' onClick={(e) => e.stopPropagation()}>
					<div className='modal-header'>
						<span className='close' onClick={toggleModal}>
							&times;
						</span>
						<h2>{product.name}</h2>
					</div>
					<div className='modal-body'>
						{product.image && (
							<img src={getProductImage(product.image)} alt={product.name} className='image' />
						)}
						<div className='description'>{product.description}</div>
						<div className='price'>${product.price}</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Product;
