import Product from './Product';
import './Products.scss';

const Products = ({ products, key }) => {
	return (
		<div key={key} className='products'>
			{products.map((product, i) => (
				<Product key={i} product={product} />
			))}
		</div>
	);
};

export default Products;
