import { BrowserRouter, Route, Switch } from 'react-router-dom';

import './App.scss';
import Toolbar from './components/Toolbar';
import Header from './components/Header';
import Home from './pages/Home';
import Blog from './pages/Blog';
import BlogPost from './pages/BlogPost';
import About from './pages/About';
import NotFound from './pages/NotFound';
import data from './data/data';
import { useEffect, useState } from 'react';
import { BlossomScene, Petal } from './components/BlossomScene';
import tree from './assets/tree.png';

export default function App() {
	const [categories, setCategories] = useState([]);
	useEffect(() => {
		const url = 'https://admin.posfacil.co/api/menu/48';

		fetch(url)
			.then((response) => response.json())
			.then((data) => data.response && setCategories(data.categories));

		const petalsTypes = [
			new Petal({ customClass: 'petal-style1' }),
			new Petal({ customClass: 'petal-style2' }),
			new Petal({ customClass: 'petal-style3' }),
			new Petal({ customClass: 'petal-style4' }),
		];
		const myBlossomSceneConfig = {
			id: 'blossom_container',
			petalsTypes,
		};
		new BlossomScene(myBlossomSceneConfig);
	}, []);

	return (
		<BrowserRouter>
			<div id='blossom_container'></div>
			<div className='App'>
				<Toolbar />
				<Header />
				<Switch>
					<Route exact path='/'>
						<Home categories={categories} />
					</Route>
					<Route exact path='/blog'>
						<Blog data={data} />
					</Route>
					<Route path='/post/:id'>
						<BlogPost data={data} />
					</Route>
					<Route path='/reservas'>
						<About />
					</Route>
					<Route path='*'>
						<NotFound />
					</Route>
				</Switch>
			</div>
		</BrowserRouter>
	);
}
