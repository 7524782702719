import { useHistory } from "react-router-dom";

import "./NotFound.scss";

const NotFound = () => {
  const history = useHistory();

  const handleClick = () => {
    console.log(history);
    history.push("/");
  };

  return (
    <main className="not-found">
      <h1>404</h1>
      <p>Opps! Can't find the page.</p>
      <button className="not-found-btn" type="button" onClick={handleClick}>
        Go To Homepage
      </button>
    </main>
  );
};

export default NotFound;
