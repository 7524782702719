import { useHistory, useLocation } from "react-router-dom";

import "./Toolbar.scss";

const Toolbar = () => {
  const history = useHistory();
  const location = useLocation();

  const handleGoBack = () => {
    history.goBack();
  };

  const handleGoForward = () => {
    history.goForward();
  };

  const handleRefresh = () => {
    history.go(0);
  };

  return (
    <div className="toolbar">
      <div className="toolbar__actables">
        <button type="button" onClick={handleGoBack}>
          <i className="fas fa-arrow-left"></i>
        </button>
        <button type="button" onClick={handleGoForward}>
          <i className="fas fa-arrow-right"></i>
        </button>
        <button type="button" onClick={handleRefresh}>
          <i className="fas fa-redo-alt"></i>
        </button>
        <div className="actables__url">
          https://sieteolas.co{location.pathname}
        </div>
      </div>
      <div className="toolbar__deco">
        <div className="deco__circle"></div>
      </div>
    </div>
  );
};

export default Toolbar;
