import "./BlogList.scss";
import BlogItem from "./BlogItem";

const BlogList = ({ data }) => {
  return (
    <ul className="blog-list">
      {data.map((el) => (
        <BlogItem item={el} key={el.id} />
      ))}
    </ul>
  );
};

export default BlogList;
