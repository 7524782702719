import { useParams } from "react-router-dom";

import "./BlogPost.scss";

const BlogPost = ({ data }) => {
  const { id } = useParams();
  const target = data.filter((el) => el.id === id)[0];
  return (
    <main className="post">
      <h1>{target.title}</h1>
      <p className="post__date">{target.createdAt}</p>
      <p className="post__content">{target.content}</p>
    </main>
  );
};

export default BlogPost;
