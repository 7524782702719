const data = [
  {
    id: "3",
    title: "React, I Love You!",
    createdAt: "2021/10/10",
    content:
      "I like the idea behind React, building a web app by combining components. It's easy to apply atmoic design to structure an app. I've been making mini projects using React and it's so much fun! Of course I face lots of challenges... haha."
  },
  {
    id: "2",
    title: "Software Engineering Bootcamp",
    createdAt: "2021/08/23",
    content:
      "The SEB has started! Today was the first day. Will I be able to become a professional developer after this bootcamp? Fingers crossed!"
  },
  {
    id: "1",
    title: "Why I Decided to Become a Developer",
    createdAt: "2021/08/10",
    content:
      "I was a graphic designer and I knew a little bit of HTML and CSS. A few years ago, I had a chance to work with web developers to create a company website. After that project, I started studying web development by myself and made my portfolio site with React. The process of learning new language was not easy but very rewarding. More importantly, it was fun! So I decided to become a developer."
  }
];

export default data;
