import "./Blog.scss";
import BlogList from "../components/BlogList";

const Blog = ({ data }) => {
  return (
    <main className="blog">
      <h1>Blog </h1>
      <BlogList data={data} />
    </main>
  );
};

export default Blog;
