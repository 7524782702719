import { Link } from "react-router-dom";
import "./BlogItem.scss";

const BlogItem = ({ item }) => {
  // const url = `/post/${item.id}`;
  return (
    <li className="blog-item">
      <Link to={"/post/" + item.id}>
        <h3>{item.title}</h3>
        <p className="blog-item__date">{item.createdAt}</p>
      </Link>
    </li>
  );
};

export default BlogItem;
